import React, { FC } from 'react'

import { FlagTooltip } from 'component'
import { IAPIDocketItem } from 'api/interfaces'
import { parseFlagDescription, parseInvalidationFlagDescription } from 'utils/commissions'

import { Box, Typography } from '@mui/material'

interface IDocketProductCellProps {
  item: IAPIDocketItem
  field: string
  showErrors?: boolean
  isExpanded?: boolean
  tag?: string
}

const DocketProductCell: FC<React.PropsWithChildren<IDocketProductCellProps>> = ({
  item,
  field,
  showErrors = true,
  isExpanded = false,
  tag = 'td',
}) => {
  const insuranceName = item.insuranceFeeReport?.insurance?.name
  const otherName = insuranceName ? 'Fonds ' + insuranceName + ' non identifié' : '-'
  let name = '-'
  let isin = undefined

  if (['other', 'structured_product', 'ucits_cumulated', 'internal_dedicated_fund'].includes(item.assetType)) {
    name = item.assetName ?? item.assetCode ?? otherName
    isin = '-'
  } else if (item.ucits) {
    name = item.ucits.name.toUpperCase()
    isin = item.ucits.isin
  } else if (item.euroFund) {
    name = item.euroFundName.toUpperCase()
    isin = item.euroFund
  }

  const Element = tag as keyof JSX.IntrinsicElements

  const classNames = ['cell--docket-product']

  const fieldFlag = parseFlagDescription(item.flagDescription).find(
    (itemFlag) => itemFlag.field === field
  )
  const fieldInvalidationFlag = parseInvalidationFlagDescription(
    item.flagInvalidationDescription
  ).find((itemInvalidationFlag) => itemInvalidationFlag.field === field)

  const hasErrors = fieldFlag !== undefined
  const isInvalidated = fieldInvalidationFlag !== undefined

  return (
    <Element
      className={classNames.join(' ')}
      key={`docket-${field}-${item.id}`}
      style={{ width: '100%' }}
    >
      <Box sx={{ display: 'flex' }}>
        {showErrors && hasErrors ? (
          <FlagTooltip
            sx={{ mr: 1 }}
            fieldFlag={fieldFlag!}
            fieldInvalidationFlag={fieldInvalidationFlag}
          />
        ) : null}
        <Typography
          component="span"
          fontWeight={500}
          noWrap={true}
          variant="subtitle1_m"
          color={hasErrors ? (isInvalidated ? 'text.primary' : 'warning.main') : 'text.primary'}
        >
          {name}
        </Typography>
      </Box>
      {isin && (
        <Box
          component="span"
          color="text.secondary"
          className={isExpanded ? 'body-s-regular' : 'body-m-regular'}
        >
          {isin}
        </Box>
      )}
    </Element>
  )
}

export default DocketProductCell
