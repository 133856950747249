import React from 'react'

import {
  IAPIClientContract,
  IAPIClientError,
  IAPICommissionClient,
  IAPICommissionContractToAffiliate,
  IAPICommissionPerYear,
  IAPICommissionSummary,
  IAPIInsurance,
  IAPIInsuranceProduct,
} from 'api/interfaces/entities'
import { customFetch } from 'api/customFetch'
import {
  API_CONTRACTS_URL,
  API_DOCKETS_URL,
  API_REPORTING_CLIENTS_URL,
  API_USER_INSURANCE_URL,
} from 'api/constants'
import { IAPIDocketItem } from 'api/interfaces'

export const getClients = async ({
  setLoading,
  filterParams,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  filterParams: {
    page: number
    count: number
    search: string
    clientLegalForm?: string
    insuranceId?: string
    year: string | null
  }
}): Promise<
  | {
      clients: IAPICommissionClient[]
      clientCount: number
      years: number[]
    }
  | undefined
> => {
  setLoading(true)
  const params = new URLSearchParams()
  const { search, clientLegalForm, insuranceId, page, count, year } = filterParams

  params.append('page', page.toString())
  params.append('count', count.toString())

  if (year !== null) {
    params.append('year', year)
  }

  if (search) {
    params.append('q', search)
  }
  if (clientLegalForm) {
    params.append('clientLegalForm', clientLegalForm)
  }
  if (insuranceId) {
    params.append('insuranceId', insuranceId)
  }

  const res = await customFetch
    .get(`${API_REPORTING_CLIENTS_URL}${params.getAll.length > 0 ? `?${params.toString()}` : null}`)
    .catch((err) => {
      setLoading(false)
      throw new Error(err.message)
    })
  if (!res || !res.ok) {
    setLoading(false)
    throw new Error('Response invalid')
  }

  const jsonRes: any = await res.json()

  if (setLoading) {
    setLoading(false)
  }

  return {
    clients: jsonRes.clients as IAPICommissionClient[],
    clientCount: jsonRes.clientTotalCount,
    years: jsonRes.years,
  }
}

export const getContractsToAffiliate = async ({
  setLoading,
  filterParams,
}: {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  filterParams: {
    page: number
    count: number
    search: string
    productId?: string
    insuranceId?: string
  }
}): Promise<
  | {
      contracts: IAPICommissionContractToAffiliate[]
      contractsCount: number
    }
  | undefined
> => {
  if (setLoading) {
    setLoading(true)
  }

  const params = new URLSearchParams()
  const { search, productId, insuranceId, page, count } = filterParams

  params.append('page', page.toString())
  params.append('count', count.toString())

  if (search) {
    params.append('q', search)
  }
  if (productId) {
    params.append('productId', productId)
  }
  if (insuranceId) {
    params.append('insuranceId', insuranceId)
  }

  const res = await customFetch
    .get(`${API_CONTRACTS_URL}${params.getAll.length > 0 ? `?${params.toString()}` : null}`)
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    if (setLoading) {
      setLoading(false)
    }
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  if (setLoading) {
    setLoading(false)
  }

  return {
    contracts: jsonRes.contracts,
    contractsCount: jsonRes.contractTotalCount,
  }
}

export const getCommissions = async ({
  setLoading,
  filterParams,
  clientId,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  filterParams: {
    contractNumber?: string
    year: string
  }
  clientId: number
}): Promise<
  | {
      commissions: IAPICommissionPerYear
    }
  | undefined
> => {
  setLoading(true)

  const params = new URLSearchParams()
  const { year, contractNumber } = filterParams

  if (year) {
    params.append('year', year)
  }

  if (contractNumber) {
    params.append('contractNumber', contractNumber)
  }

  const res = await customFetch
    .get(
      `${API_REPORTING_CLIENTS_URL}/${clientId}/commission-details${
        params.getAll.length > 0 ? `?${params.toString()}` : null
      }`
    )
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    setLoading(false)
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  setLoading(false)

  return {
    commissions: jsonRes,
  }
}

export const getCommissionsYears = async ({
  clientId,
  filterParams,
}: {
  clientId: number
  filterParams: {
    contractNumber?: string
  }
}): Promise<
  | {
      years: number[]
    }
  | undefined
> => {
  const params = new URLSearchParams()

  const { contractNumber } = filterParams

  if (contractNumber) {
    params.append('contractNumber', contractNumber)
  }

  const res = await customFetch
    .get(
      `${API_REPORTING_CLIENTS_URL}/${clientId}/commission-years${
        params.getAll.length > 0 ? `?${params.toString()}` : null
      }`
    )
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return { years: jsonRes.commissionYears }
}

export const getCommissionsSummary = async ({
  clientId,
}: {
  clientId: number
}): Promise<
  | {
      summary: IAPICommissionSummary
    }
  | undefined
> => {
  const res = await customFetch.get(`${API_REPORTING_CLIENTS_URL}/${clientId}`).catch((err) => {
    throw new Error(err.message)
  })

  if (!res || !res.ok) {
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return {
    summary: jsonRes,
  }
}

export const getClientContracts = async ({
  clientId,
}: {
  clientId: number
}): Promise<
  | {
      automaticallyAttachedContracts: IAPIClientContract[]
      manuallyAttachedContracts: IAPIClientContract[]
    }
  | undefined
> => {
  const res = await customFetch
    .get(`${API_REPORTING_CLIENTS_URL}/${clientId}/contracts`)
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return {
    automaticallyAttachedContracts: jsonRes.automaticallyAttachedContracts,
    manuallyAttachedContracts: jsonRes.manuallyAttachedContracts,
  }
}

export const getClientErrors = async ({
  clientId,
}: {
  clientId: number
}): Promise<
  | {
      activeErrors: IAPIClientError[]
      deactivatedErrors: IAPIClientError[]
    }
  | undefined
> => {
  const res = await customFetch
    .get(`${API_REPORTING_CLIENTS_URL}/${clientId}/errors`)
    .catch((err) => {
      console.log(err)
    })

  if (!res || !res.ok) {
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return {
    activeErrors: jsonRes.activeErrors,
    deactivatedErrors: jsonRes.deactivatedErrors,
  }
}

export const getDocket = async ({
  reportId,
  reportItemId,
}: {
  reportId: number
  reportItemId: number
}): Promise<
  | {
      docket: IAPIDocketItem | undefined
    }
  | undefined
> => {
  const params = new URLSearchParams()

  params.append('type', 'error_detected')

  const res = await customFetch
    .get(
      `${API_DOCKETS_URL}/${reportId}/items${
        params.getAll.length > 0 ? `?${params.toString()}` : null
      }`
    )
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return jsonRes
    ? {
        docket: jsonRes.find((item: IAPIDocketItem) => item.id === reportItemId),
      }
    : undefined
}

export const getContractsInsurances = async (
  hasClients: boolean
): Promise<
  | {
      insurances: IAPIInsurance[]
    }
  | undefined
> => {
  const params = new URLSearchParams()

  params.append('hasClients', hasClients.toString())

  const res = await customFetch
    .get(
      `${API_CONTRACTS_URL}/insurances${params.getAll.length > 0 ? `?${params.toString()}` : null}`
    )
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    return
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return jsonRes
    ? {
        insurances: jsonRes,
      }
    : undefined
}

export const getContractsProducts = async ({
  insuranceId,
}: {
  insuranceId?: string
}): Promise<
  | {
      products: IAPIInsuranceProduct[]
    }
  | undefined
> => {
  const params = new URLSearchParams()

  params.append('hasClients', 'true')

  const res = await customFetch
    .get(
      `${API_USER_INSURANCE_URL}/${insuranceId}/products${
        params.getAll.length > 0 ? `?${params.toString()}` : null
      }`
    )
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    return
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return jsonRes
    ? {
        products: jsonRes.results,
      }
    : undefined
}

export const getCommissionContracts = async ({
  clientId,
}: {
  clientId: number
}): Promise<
  | {
      contracts: any[]
    }
  | undefined
> => {
  const res = await customFetch
    .get(`${API_REPORTING_CLIENTS_URL}/${clientId}/commission-contracts`)
    .catch((err) => {
      throw new Error(err.message)
    })

  if (!res || !res.ok) {
    throw new Error('Response invalid')
  }

  const jsonRes: void | any = await res.json().catch((err) => {
    throw new Error(err.message)
  })

  return { contracts: jsonRes.commissionContracts }
}
