import React, { FC } from 'react'

import { FlagTooltip } from 'component'
import { formatCurrencySymbol } from 'utils'
import { parseFlagDescription, parseInvalidationFlagDescription } from 'utils/commissions'

import { Box, Stack, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface IDocketCurrencyCellProps {
  item: any
  field: string
  value?: any
  showErrors?: boolean
  tag?: string
  fontWeight?: 400 | 500 | 600 | 700
  sx?: SxProps<Theme>
}

const DocketCurrencyCell: FC<React.PropsWithChildren<IDocketCurrencyCellProps>> = ({
  item,
  field,
  value,
  showErrors = true,
  tag = 'td',
  fontWeight = 400,
  sx
}) => {
  if (value === undefined) {
    value = item[field]
  }

  const Element = tag as keyof JSX.IntrinsicElements
  const classNames = ['cell--docket-currency cell-number']
  // const formattedValue =
  //   field === ''
  //     ? ''
  //     : formatCurrencySymbol(
  //         value,
  //         item.currency === 'OTHER' ? item.currencyOther : item.currency
  //       ) || '-'

  let fieldFlag = undefined;
  let fieldInvalidationFlag = undefined;
  let hasErrors = false;
  let isInvalidated = false;
  let provider = item.insuranceFeeReport ? item.insuranceFeeReport.insurance.provider : undefined;
  let formattedValue = '-'

  if (!(provider === 'intencial' && field === 'baseAmount' && value === 0.0)) {
    if (item.currency === 'OTHER') {
      formattedValue = formatCurrencySymbol(value, item.currencyOther)
    } else {
      formattedValue = formatCurrencySymbol(value, item.currency)
    }
  }

  if (item.flagDescription) {
    fieldFlag = parseFlagDescription(item.flagDescription).find(
      (itemFlag) => itemFlag.field === field
    )
    fieldInvalidationFlag = parseInvalidationFlagDescription(
      item.flagInvalidationDescription
    ).find((itemInvalidationFlag) => itemInvalidationFlag.field === field)

    hasErrors = fieldFlag !== undefined
    isInvalidated = fieldInvalidationFlag !== undefined
  }

  if (formattedValue === '-') {
    classNames.push('cell--docket-no-value')
  }

  const isBold = (hasErrors && showErrors && !isInvalidated);

  return (
    <Element className={classNames.join(' ')} key={`docket-${field}-${item.id}`}>
      <Stack direction="row">
        {showErrors && hasErrors ? (
          <FlagTooltip
            fieldFlag={fieldFlag!}
            fieldInvalidationFlag={fieldInvalidationFlag}
            sx={{ mr: 1, svg: { display: 'block' } }}
          />
        ) : null}
        <Box
          fontWeight={(fontWeight !== 400 ? fontWeight : isBold ? 500 : 400)}
          color={
            hasErrors && showErrors
              ? isInvalidated
                ? 'text.primary'
                : 'warning.main'
              : 'text.primary'
          }
          sx={sx ? sx: {}}
        >
          {formattedValue}
        </Box>
      </Stack>
    </Element>
  )
}

export default DocketCurrencyCell
